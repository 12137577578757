import React, { useState, useEffect } from 'react';
import { Button, Spinner, Form, Container, Row, Col, Card } from 'react-bootstrap';
import Papa from 'papaparse';
import lotteryGamesData from '../../data/yolo-picker-lottery-games.csv';
import './LotteryPicker.css';
import TimestampDisplay from '../common/TimestampDisplay';
import theme from '../../theme';

const LotteryPicker = () => {
  const [countries, setCountries] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('USA');
  const [selectedGame, setSelectedGame] = useState('');
  const [gameDetails, setGameDetails] = useState(null);
  const [lotteryNumbers, setLotteryNumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [numTickets, setNumTickets] = useState(2);
  const [lastFlipTime, setLastFlipTime] = useState(null);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    Papa.parse(lotteryGamesData, {
      download: true,
      header: true,
      complete: (results) => {
        const data = results.data;
        const uniqueCountries = [...new Set(data.map(item => item.Country))];
        setCountries(uniqueCountries);

        const countryGames = data.filter(item => item.Country === selectedCountry);
        setGames(countryGames);

        if (countryGames.length > 0) {
          setSelectedGame(countryGames[0].Game);
          setGameDetails(countryGames[0]);
        }
      },
    });
  }, [selectedCountry]);

  useEffect(() => {
    let interval;
    if (lastFlipTime) {
      interval = setInterval(() => {
        setCounter(Math.floor((Date.now() - lastFlipTime) / 1000));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [lastFlipTime]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleGameChange = (e) => {
    const selectedGame = games.find(game => game.Game === e.target.value);
    setSelectedGame(selectedGame.Game);
    setGameDetails(selectedGame);
  };

  const handleNumTicketsChange = (e) => {
    setNumTickets(Math.min(parseInt(e.target.value), 50));
  };

  const generateLotteryNumbers = () => {
    if (!gameDetails) return;
    setLoading(true);
    setLotteryNumbers([]);

    setTimeout(() => {
      const tickets = [];
      for (let i = 0; i < numTickets; i++) {
        const {
          allow_repetition,
          first_range_from,
          first_range_to,
          first_range_npicks,
          first_allow_sort,
          second_range_from,
          second_range_to,
          second_range_npicks,
          second_allow_sort,
        } = gameDetails;

        const firstRangeNumbers = allow_repetition === '0'
          ? generateUniqueNumbers(parseInt(first_range_from), parseInt(first_range_to), parseInt(first_range_npicks), first_allow_sort)
          : generateRandomNumbers(parseInt(first_range_from), parseInt(first_range_to), parseInt(first_range_npicks));

        const secondRangeNumbers = second_range_npicks
          ? generateUniqueNumbers(parseInt(second_range_from), parseInt(second_range_to), parseInt(second_range_npicks), second_allow_sort)
          : [];

        tickets.push({ firstRangeNumbers, secondRangeNumbers });
      }
      setLotteryNumbers(tickets);
      setLastFlipTime(Date.now());
      setLoading(false);
    }, 2000);
  };

  const generateUniqueNumbers = (from, to, npicks, allowSort) => {
    const numbers = new Set();
    while (numbers.size < npicks) {
      const number = Math.floor(Math.random() * (to - from + 1)) + from;
      numbers.add(number);
    }
    const numArray = Array.from(numbers);
    return allowSort ? numArray.sort((a, b) => a - b) : numArray;
  };

  const generateRandomNumbers = (from, to, npicks) => {
    const numbers = [];
    for (let i = 0; i < npicks; i++) {
      const number = Math.floor(Math.random() * (to - from + 1)) + from;
      numbers.push(number);
    }
    return numbers;
  };

  return (
    <Container className="lottery-picker">
      <Card className="lottery-card">
        <Card.Header className="text-center">
          <h2 className="lottery-title">Lottery Picker</h2>
        </Card.Header>
        <Card.Body>
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={6}>
              <Form className="form-container">
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="country" className="mb-3">
                      <Form.Label>Country:</Form.Label>
                      <Form.Control 
                        as="select" 
                        value={selectedCountry} 
                        onChange={handleCountryChange}
                        className="form-input"
                      >
                        {countries.map(country => (
                          <option key={country} value={country}>{country}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="game" className="mb-3">
                      <Form.Label>Game:</Form.Label>
                      <Form.Control 
                        as="select" 
                        value={selectedGame} 
                        onChange={handleGameChange}
                        className="form-input"
                      >
                        {games.map(game => (
                          <option key={game.Game} value={game.Game}>{game.GameDisplayName}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-center mb-4">
                  <Col xs={12} sm={6}>
                    <Form.Group controlId="numTickets">
                      <Form.Label>Number of Tickets:</Form.Label>
                      <Form.Control
                        type="number"
                        value={numTickets}
                        onChange={handleNumTicketsChange}
                        min="1"
                        max="50"
                        className="form-input"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs={12} sm={6} className="d-grid">
                    <Button 
                      onClick={generateLotteryNumbers} 
                      className="generate-button"
                      disabled={loading}
                    >
                      {loading ? 'Generating...' : `Generate ${numTickets === 1 ? 'Ticket' : 'Tickets'}`}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          
          {loading && (
            <div className="loading-spinner text-center mt-4">
              <Spinner animation="border" role="status" variant="primary" />
              <p className="mt-2">Generating tickets...</p>
            </div>
          )}
          
          <div className="lottery-tickets mt-4">
            {lotteryNumbers.length > 0 && (
              <div className="tickets-container">
                <h5 className="text-center mb-4">Your Lucky Numbers</h5>
                <Row className="justify-content-center">
                  {lotteryNumbers.map((ticket, ticketIndex) => (
                    <Col key={ticketIndex} xs={12} className="mb-4">
                      <div className="ticket-wrapper">
                        <div className="ticket-number">{`Ticket ${ticketIndex + 1}`}</div>
                        <div className="ticket">
                          {ticket.firstRangeNumbers.map((number, index) => (
                            <span key={index} className="number-ball first-range">{number}</span>
                          ))}
                          {ticket.secondRangeNumbers.length > 0 && (
                            <span className="ball-divider"></span>
                          )}
                          {ticket.secondRangeNumbers.map((number, index) => (
                            <span key={index} className="number-ball second-range">{number}</span>
                          ))}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
                
                <div className="timestamp-container text-center mt-4">
                  <TimestampDisplay label="Last Generated at:" timestamp={lastFlipTime} />
                  <p className="time-elapsed">Seconds Since Last Ticket Generation: {counter}</p>
                </div>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default LotteryPicker;
