import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import { 
  BorderOutlined, 
  DollarOutlined, 
  PercentageOutlined, 
  NumberOutlined,
  StarOutlined,
  TrophyOutlined 
} from '@ant-design/icons';
import theme from '../../theme';
import './Home.css';

const Home = () => {
  const tools = [
    {
      title: "Coin Flipper",
      description: "Flip a virtual coin for quick decision making",
      icon: <PercentageOutlined />,
      path: "/coin-flipper",
      color: theme.colors.primary
    },
    {
      title: "Dice Roller",
      description: "Roll virtual dice for games or random choices",
      icon: <BorderOutlined />,
      path: "/dice-roller",
      color: theme.colors.secondary
    },
    {
      title: "Lottery Generator",
      description: "Generate random lottery numbers for your ticket",
      icon: <StarOutlined />,
      path: "/lottery-picker",
      color: theme.colors.tertiary
    },
    {
      title: "Stock Picker",
      description: "Random stock selection from major indices",
      icon: <DollarOutlined />,
      path: "/stock-picker",
      color: theme.colors.success
    },
    {
      title: "Random Number",
      description: "Generate random numbers in custom ranges",
      icon: <NumberOutlined />,
      path: "/random-number-generator",
      color: theme.colors.warning
    },
    {
      title: "ARCL Points",
      description: "Cricket league points calculator",
      icon: <TrophyOutlined />,
      path: "/arcl",
      color: theme.colors.error
    }
  ];

  return (
    <div className="home-container">
      <div className="hero-section">
        <h1 className="app-title">Welcome to Yolo Picker</h1>
        <p className="app-subtitle">Your elegant companion for random decisions</p>
      </div>

      <div className="tools-section">
        <h2 className="section-title">Choose Your Tool</h2>
        <Row xs={1} md={2} lg={3} className="g-4">
          {tools.map((tool, idx) => (
            <Col key={idx}>
              <Link to={tool.path} className="tool-card-link">
                <Card className="tool-card">
                  <div 
                    className="tool-icon-wrapper"
                    style={{ backgroundColor: tool.color + '20' }} // 20 is hex for 12% opacity
                  >
                    <div 
                      className="tool-icon"
                      style={{ color: tool.color }}
                    >
                      {tool.icon}
                    </div>
                  </div>
                  <Card.Body>
                    <Card.Title>{tool.title}</Card.Title>
                    <Card.Text>{tool.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>

      <div className="about-section">
        <h2 className="section-title">About Yolo Picker</h2>
        <div className="about-content">
          <div className="floating-graphics">
            <div className="floating-element dice"></div>
            <div className="floating-element coin"></div>
            <div className="floating-element number">7</div>
          </div>
          <p>
            Yolo Picker is your go-to app for making random selections and decisions. 
            Whether you need to flip a coin, roll dice, pick lottery numbers, or make any 
            random choice, we've got you covered with beautiful, elegant tools.
          </p>
        </div>
      </div>

      <footer className="home-footer">
        <p> {new Date().getFullYear()} Yolo Picker. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Home;
