import React, { useState, useEffect } from 'react';
import { Button, Form, Table, Spinner, Container, Row, Col, Card } from 'react-bootstrap';
import TimestampDisplay from '../common/TimestampDisplay';
import theme from '../../theme';
import './CoinFlipper.css';

const CoinFlipper = () => {
  const [numCoins, setNumCoins] = useState(1);
  const [results, setResults] = useState([]);
  const [counts, setCounts] = useState({ heads: 0, tails: 0 });
  const [lastFlipTime, setLastFlipTime] = useState(null);
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let interval;
    if (lastFlipTime) {
      interval = setInterval(() => {
        setCounter(Math.floor((Date.now() - lastFlipTime) / 1000));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [lastFlipTime]);

  const flipCoins = () => {
    setLoading(true);
    setResults([]);
    const outcomes = [];
    let heads = 0;
    let tails = 0;

    setTimeout(() => {
      for (let i = 0; i < numCoins; i++) {
        const outcome = Math.random() < 0.5 ? 'Heads' : 'Tails';
        outcomes.push(outcome);
        if (outcome === 'Heads') heads++;
        else tails++;
      }

      setResults(outcomes);
      setCounts({ heads, tails });
      setLastFlipTime(Date.now());
      setLoading(false);
    }, 1000); // Simulate a delay
  };

  const handleNumCoinsChange = (e) => {
    setNumCoins(Math.min(parseInt(e.target.value), 2000)); // limit to 2000
  };

  return (
    <Container className="coin-flipper">
      <Card className="coin-card">
        <Card.Header className="text-center">
          <h2 className="coin-title">Coin Flipper</h2>
        </Card.Header>
        <Card.Body>
          <Row className="justify-content-center">
            <Col xs={12} md={6} lg={4}>
              <Form className="form-container">
                <Form.Group controlId="numCoins" className="mb-4">
                  <Form.Label>Number of Coins:</Form.Label>
                  <Form.Control
                    type="number"
                    value={numCoins}
                    onChange={handleNumCoinsChange}
                    min="1"
                    max="50"
                    className="form-input"
                  />
                </Form.Group>
                <div className="d-grid">
                  <Button 
                    onClick={flipCoins} 
                    disabled={loading}
                    className="flip-button"
                  >
                    {loading ? 'Flipping...' : `Flip ${numCoins === 1 ? 'Coin' : 'Coins'}`}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
          
          <div className="results mt-4">
            {loading && (
              <div className="loading-spinner text-center">
                <Spinner animation="border" role="status" variant="primary" />
                <p className="mt-2">Flipping coins...</p>
              </div>
            )}
            
            {!loading && results.length > 0 && (
              <div className="result-container">
                {numCoins > 1 && (
                  <div className="stats-container mt-4">
                    <h5 className="text-center mb-3">Results Distribution</h5>
                    <Row className="justify-content-center">
                      <Col xs={12} md={8} lg={6}>
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Outcome</th>
                              <th>Count</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Heads</td>
                              <td>{counts.heads}</td>
                            </tr>
                            <tr>
                              <td>Tails</td>
                              <td>{counts.tails}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                )}
                
                <div className="flipped-coins">
                  {results.map((result, index) => (
                    <div key={index} className={`coin ${result.toLowerCase()}`}>
                      {result}
                    </div>
                  ))}
                </div>
                
                <div className="timestamp-container text-center mt-4">
                  <TimestampDisplay label="Last Flip" timestamp={lastFlipTime} />
                  <p className="time-elapsed">Seconds Since Last Flip: {counter}</p>
                </div>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CoinFlipper;
