// Theme configuration for the Yolo Picker app
// This file contains all the design tokens and theme variables

const theme = {
  // Color palette
  colors: {
    primary: '#5E60CE', // Main brand color - elegant purple
    secondary: '#64DFDF', // Accent color - teal
    tertiary: '#80FFDB', // Light mint
    background: '#FFFFFF',
    cardBackground: '#F8F9FA',
    textPrimary: '#333333',
    textSecondary: '#6C757D',
    success: '#48BF84',
    warning: '#F9C74F',
    error: '#F25C54',
    border: '#E9ECEF',
    lightGray: '#F1F3F5',
    darkGray: '#495057',
    gradientStart: '#5E60CE',
    gradientEnd: '#64DFDF',
  },
  
  // Typography
  typography: {
    fontFamily: "'Poppins', 'Helvetica Neue', sans-serif",
    headingFontFamily: "'Playfair Display', serif",
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      md: '1.125rem',
      lg: '1.25rem',
      xl: '1.5rem',
      xxl: '2rem',
      xxxl: '2.5rem',
    },
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
  },
  
  // Spacing
  spacing: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem',
    xxl: '3rem',
  },
  
  // Borders and Shadows
  borderRadius: {
    sm: '0.25rem',
    md: '0.5rem',
    lg: '1rem',
    xl: '2rem',
    circle: '50%',
  },
  
  shadows: {
    sm: '0 2px 4px rgba(0, 0, 0, 0.05)',
    md: '0 4px 6px rgba(0, 0, 0, 0.07)',
    lg: '0 10px 15px rgba(0, 0, 0, 0.1)',
    xl: '0 15px 25px rgba(0, 0, 0, 0.12)',
  },
  
  // Transitions
  transitions: {
    default: '0.3s ease',
    fast: '0.15s ease',
    slow: '0.5s ease',
  },
  
  // Z-index layers
  zIndex: {
    base: 1,
    dropdown: 10,
    sticky: 100,
    modal: 1000,
    tooltip: 1100,
  },
  
  // Component-specific styles
  components: {
    button: {
      primaryGradient: 'linear-gradient(45deg, #5E60CE, #64DFDF)',
      secondaryGradient: 'linear-gradient(45deg, #64DFDF, #80FFDB)',
      boxShadow: '0 4px 14px rgba(94, 96, 206, 0.25)',
      hoverBoxShadow: '0 6px 20px rgba(94, 96, 206, 0.35)',
    },
    card: {
      boxShadow: '0 10px 30px rgba(0, 0, 0, 0.05)',
      hoverBoxShadow: '0 15px 35px rgba(0, 0, 0, 0.1)',
      activeBorder: '2px solid #5E60CE',
    },
    numberBall: {
      primary: '#5E60CE',
      secondary: '#64DFDF',
      tertiary: '#80FFDB',
      success: '#48BF84',
      warning: '#F9C74F',
      error: '#F25C54',
    },
  },
};

export default theme;
