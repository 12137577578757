import React, { useState, useEffect } from 'react';
import { Button, Form, Table, Spinner, Container, Row, Col, Card } from 'react-bootstrap';
import TimestampDisplay from '../common/TimestampDisplay';
import theme from '../../theme';
import './DiceRoller.css';

const DiceRoller = () => {
  const [numDice, setNumDice] = useState(1);
  const [results, setResults] = useState([]);
  const [counts, setCounts] = useState({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 });
  const [lastRollTime, setLastRollTime] = useState(null);
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let interval;
    if (lastRollTime) {
      interval = setInterval(() => {
        setCounter(Math.floor((Date.now() - lastRollTime) / 1000));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [lastRollTime]);

  const rollDice = () => {
    setLoading(true);
    setResults([]);
    const outcomes = [];
    const newCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 };

    setTimeout(() => {
      for (let i = 0; i < numDice; i++) {
        const outcome = Math.floor(Math.random() * 6) + 1;
        outcomes.push(outcome);
        newCounts[outcome]++;
      }

      setResults(outcomes);
      setCounts(newCounts);
      setLastRollTime(Date.now());
      setLoading(false);
    }, 1000); // Simulate a delay
  };

  const handleNumDiceChange = (e) => {
    setNumDice(Math.min(parseInt(e.target.value), 10000));
  };

  return (
    <Container className="dice-roller">
      <Card className="dice-roller-card">
        <Card.Header className="text-center">
          <h2 className="dice-title">Dice Roller</h2>
        </Card.Header>
        <Card.Body>
          <Row className="justify-content-center">
            <Col xs={12} md={6} lg={4}>
              <Form className="form-container">
                <Form.Group controlId="numDice" className="mb-4">
                  <Form.Label>Number of Dice:</Form.Label>
                  <Form.Control
                    type="number"
                    value={numDice}
                    onChange={handleNumDiceChange}
                    min="1"
                    max="50"
                    className="form-input"
                  />
                </Form.Group>
                <div className="d-grid">
                  <Button 
                    onClick={rollDice} 
                    disabled={loading}
                    className="roll-button"
                  >
                    {loading ? 'Rolling...' : `Roll ${numDice === 1 ? 'Die' : 'Dice'}`}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
          
          <div className="results mt-4">
            {loading && (
              <div className="loading-spinner text-center">
                <Spinner animation="border" role="status" variant="primary" />
                <p className="mt-2">Rolling dice...</p>
              </div>
            )}
            
            {!loading && results.length > 0 && (
              <div className="result-container">
                <div className="rolled-dice">
                  {results.map((result, index) => (
                    <div key={index} className="die">
                      {result}
                    </div>
                  ))}
                </div>
                
                {numDice > 1 && (
                  <div className="stats-container mt-4">
                    <h5 className="text-center mb-3">Results Distribution</h5>
                    <Row className="justify-content-center">
                      <Col xs={12} md={8} lg={6}>
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Side</th>
                              <th>Count</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(counts).map(([side, count]) => (
                              <tr key={side}>
                                <td>{side}</td>
                                <td>{count}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                )}
                
                {lastRollTime && (
                  <div className="timestamp-container text-center mt-4">
                    <TimestampDisplay label="Last Roll" timestamp={lastRollTime} />
                    <p className="time-elapsed">Seconds Since Last Roll: {counter}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default DiceRoller;
