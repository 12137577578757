import React from 'react';
import PropTypes from 'prop-types';
import { Boules, BoxingGlove, TennisBall, ProhibitInset } from "@phosphor-icons/react";
import './PlayerTile.css';

const PlayerTile = ({ player, onSelect, onToggleNoBowling }) => {
  // Handle clicking on the bowling icon
  const handleBowlingIconClick = (e) => {
    e.stopPropagation(); // Prevent triggering the parent onClick (selection)
    onToggleNoBowling(player);
  };

  return (
    <div 
      className={`player-tile ${player.isSelected ? 'selected' : ''}`}
      onClick={() => onSelect(player)}
    >
      <div className="player-name">{player.displayName || player.PlayerName}</div>
      <div className="player-icons">
        {player.is_wicket_keeper === '1' && (
          <BoxingGlove size={16} color="#0e816e" weight="fill" className="icon icon-keeper" />
        )}
        {/* Conditionally show bowling icons or no bowling icon */}
        {player.noBowling ? (
          <div onClick={handleBowlingIconClick} className="icon-container">
            <ProhibitInset size={16} color="#a30505" weight="fill" className="icon icon-no-bowling" />
          </div>
        ) : (
          <>
            {player.bowling_flag === '1' && (
              <div onClick={handleBowlingIconClick} className="icon-container">
                <Boules size={16} color="#a30505" weight="fill" className="icon icon-bowler" />
              </div>
            )}
            {['2', '3'].includes(player.bowling_flag) && (
              <div onClick={handleBowlingIconClick} className="icon-container">
                <TennisBall size={16} color="#f0d738" weight="fill" className="icon icon-bowler" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

PlayerTile.propTypes = {
  player: PropTypes.shape({
    PlayerName: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    is_wicket_keeper: PropTypes.string.isRequired,
    bowling_flag: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    noBowling: PropTypes.bool,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  onToggleNoBowling: PropTypes.func.isRequired,
};

export default PlayerTile;