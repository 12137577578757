import React, { useState } from 'react';
import { Navbar, Container, Nav, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import logo from '../../assets/yolo.png';
import theme from '../../theme';
import './Header.css';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleClose = () => setShowMenu(false);
  const handleShow = () => setShowMenu(true);

  const navLinks = [
    { path: '/', title: 'Home' },
    { path: '/coin-flipper', title: 'Flip a Coin' },
    { path: '/dice-roller', title: 'Dice Roller' },
    { path: '/lottery-picker', title: 'Lottery Generator' },
    { path: '/stock-picker', title: 'Stock Picker' },
    { path: '/random-number-generator', title: 'Random Number' },
    { path: '/arcl', title: 'ARCL Points' },
    { path: '/brcl2', title: 'BRCL' },
  ];

  return (
    <>
      <Navbar expand="lg" className="header-navbar">
        <Container>
          <Navbar.Brand as={Link} to="/" className="brand">
            <img 
              src={logo} 
              alt="Yolo Picker Logo" 
              className="logo" 
            />
            <span className="brand-name">Yolo Picker</span>
          </Navbar.Brand>
          
          <div className="d-none d-lg-flex nav-links-container">
            {navLinks.slice(0, 5).map((link, index) => (
              <Nav.Link 
                key={index}
                as={Link} 
                to={link.path} 
                className="nav-link"
              >
                {link.title}
              </Nav.Link>
            ))}
          </div>
          
          <button className="menu-toggle d-lg-none" onClick={handleShow}>
            <MenuOutlined />
          </button>
        </Container>
      </Navbar>

      {/* Mobile Menu */}
      <Offcanvas show={showMenu} onHide={handleClose} placement="end" className="mobile-menu">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Yolo Picker</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mobile-nav-links">
            {navLinks.map((link, index) => (
              <Nav.Link 
                key={index}
                as={Link} 
                to={link.path} 
                onClick={handleClose}
                className="mobile-nav-link"
              >
                {link.title}
              </Nav.Link>
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
