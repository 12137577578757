import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { XCircle, Swap, Crown, UserCheck, DotsThreeVertical, SortAscending, Share, 
         Trophy, Clock, Baseball, ShieldStar, UsersThree, ArrowClockwise } from "@phosphor-icons/react";
import { Button, Input, Dropdown, Menu, TimePicker, Modal } from 'antd';
import moment from 'moment';
import { generateBalancedTeams, calculateTeamRating, assignRandomCaptain, selectRandomJack } from '../../../utils/sports/brcl/teamUtils';
import { sortPlayersAlphabetically, addNewPlayer } from '../../../utils/sports/brcl/playerUtils';
import './TeamsPage.css';

// Helper function for team naming
const getTeamName = (index) => {
  if (index === 0) return "Boomer";
  if (index === 1) return "CenterFresh";
  return `Team ${index + 1}`;
};

const TeamsPage = () => {
  const location = useLocation();
  const [teams, setTeams] = useState([]);
  const [unassignedPlayers, setUnassignedPlayers] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);
  const [newPlayerName, setNewPlayerName] = useState('');
  const [format, setFormat] = useState('T20');
  const [overs, setOvers] = useState(20);
  const [maxOversPerBowler, setMaxOversPerBowler] = useState('3');
  const [gameTime, setGameTime] = useState(moment('20:45', 'HH:mm'));
  const [jackPlayer, setJackPlayer] = useState(null); // State for Jack player
  const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility

  useEffect(() => {
    let initialTeams = location.state?.teams || [];
    
    // Update team names if they are the default names
    if (initialTeams.length > 0) {
      initialTeams = initialTeams.map((team, index) => {
        // Only rename if using the default naming pattern
        if (team.name === `Team ${index + 1}`) {
          return { ...team, name: getTeamName(index) };
        }
        return team;
      });
    }
    
    setTeams(initialTeams);
    setAllPlayers(location.state?.allPlayers || []);
    updateUnassignedPlayers(initialTeams, location.state?.allPlayers || []);
  }, [location.state]);

  const updateUnassignedPlayers = (currentTeams, allPlayersList) => {
    const assignedPlayers = currentTeams.flatMap(team => team.players);
    const unassigned = allPlayersList.filter(player => 
      !assignedPlayers.some(p => p.PlayerName === player.PlayerName)
    );
    setUnassignedPlayers(unassigned);
  };

  const handleAddPlayer = () => {
    const updatedPlayers = addNewPlayer(newPlayerName, allPlayers);
    setAllPlayers(updatedPlayers);
    setUnassignedPlayers(prev => [...prev, updatedPlayers[updatedPlayers.length - 1]]);
    setNewPlayerName('');
  };

  const handleSelectRandomJack = () => {
    const selectedJack = selectRandomJack(teams);
    if (selectedJack) {
      assignJack(selectedJack); // Use the existing assignJack function for consistency
    }
  };

  const isJackSelectionEnabled = () => {
    return (
      teams.length === 2 &&
      Math.abs(teams[0].players.length - teams[1].players.length) === 1
    );
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const sourceId = source.droppableId;
    const destId = destination.droppableId;

    if (sourceId === destId) {
      // Reordering within the same list
      if (sourceId === 'unassigned') {
        const items = Array.from(unassignedPlayers);
        const [reorderedItem] = items.splice(source.index, 1);
        items.splice(destination.index, 0, reorderedItem);
        setUnassignedPlayers(items);
      } else {
        setTeams(prev => prev.map(team => {
          if (team.id === sourceId) {
            const items = Array.from(team.players);
            const [reorderedItem] = items.splice(source.index, 1);
            items.splice(destination.index, 0, reorderedItem);
            return { ...team, players: items };
          }
          return team;
        }));
      }
    } else {
      // Moving between lists
      if (sourceId === 'unassigned') {
        const items = Array.from(unassignedPlayers);
        const [movedItem] = items.splice(source.index, 1);
        setUnassignedPlayers(items);
        setTeams(prev => prev.map(team => {
          if (team.id === destId) {
            return { ...team, players: [...team.players, movedItem] };
          }
          return team;
        }));
      } else if (destId === 'unassigned') {
        setTeams(prev => prev.map(team => {
          if (team.id === sourceId) {
            const items = Array.from(team.players);
            const [movedItem] = items.splice(source.index, 1);
            setUnassignedPlayers(prevUnassigned => [...prevUnassigned, movedItem]);
            return { ...team, players: items };
          }
          return team;
        }));
      } else {
        setTeams(prev => {
          const newTeams = [...prev];
          const sourceTeam = newTeams.find(t => t.id === sourceId);
          const destTeam = newTeams.find(t => t.id === destId);
          const [movedItem] = sourceTeam.players.splice(source.index, 1);
          destTeam.players.splice(destination.index, 0, movedItem);
          return newTeams;
        });
      }
    }
  };

  const removePlayer = (player, teamIndex) => {
    setTeams(prevTeams => {
      const newTeams = [...prevTeams];
      newTeams[teamIndex].players = newTeams[teamIndex].players.filter(p => p.PlayerName !== player.PlayerName);
      return newTeams;
    });
    setUnassignedPlayers(prev => [...prev, player]);
  };

  const movePlayer = (player, fromTeamIndex, toTeamIndex) => {
    setTeams(prevTeams => {
      const newTeams = [...prevTeams];
      newTeams[fromTeamIndex].players = newTeams[fromTeamIndex].players.filter(p => p.PlayerName !== player.PlayerName);
      newTeams[toTeamIndex].players.push(player);
      return newTeams;
    });
  };

  const handleReshuffle = () => {
    const selectedPlayers = teams.flatMap((team) =>
      team.players.map((player) => ({ ...player, isCaptain: false }))
    );
    const newTeams = generateBalancedTeams(selectedPlayers, teams.length);
    
    const updatedTeams = newTeams.map((team, index) => ({
      id: `team-${index + 1}`,
      name: getTeamName(index),
      players: sortPlayersAlphabetically(team),
    }));

    const teamsWithCaptains = assignRandomCaptain(updatedTeams);
    setTeams(teamsWithCaptains);
    setJackPlayer(null);

    // Log team ratings
    teamsWithCaptains.forEach((team, index) => {
      const ratings = calculateTeamRating(team.players);
      console.log(`Team ${index + 1} Ratings:`, ratings);
    });
  };

  const handleTeamNameChange = (teamId, newName) => {
    setTeams(prevTeams => prevTeams.map(team => 
      team.id === teamId ? { ...team, name: newName } : team
    ));
  };

  const sortTeamPlayers = (teamId) => {
    setTeams(prevTeams => prevTeams.map(team => 
      team.id === teamId ? { ...team, players: [...team.players].sort((a, b) => a.displayName.localeCompare(b.displayName)) } : team
    ));
  };

  const assignCaptain = (teamId, player) => {
    setTeams(prevTeams => prevTeams.map(team => 
      team.id === teamId ? { ...team, players: team.players.map(p => p.PlayerName === player.PlayerName ? { ...p, isCaptain: true } : { ...p, isCaptain: false }) } : team
    ));
  };

  const assignJack = (player) => {
    setJackPlayer(player.PlayerName);

    setTeams((prevTeams) =>
      prevTeams.map((team) => {
        const isInTeam = team.players.some(
          (p) => p.PlayerName === player.PlayerName
        );
        const updatedPlayers = isInTeam
          ? team.players.map((p) => ({
              ...p,
              isJack: p.PlayerName === player.PlayerName,
            }))
          : [...team.players, { ...player, isJack: true }];

        return {
          ...team,
          players: updatedPlayers,
        };
      })
    );
  };

  const playerMenu = (teamId, player) => (
    <Menu>
      <Menu.Item key="1" onClick={() => assignCaptain(teamId, player)}>
        Make Captain
      </Menu.Item>
      <Menu.Item key="2" onClick={() => assignJack(player)}>
        Make Jack
      </Menu.Item>
    </Menu>
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="teams-page">
        <div className="add-player">
          <Input
            value={newPlayerName}
            onChange={(e) => setNewPlayerName(e.target.value)}
            placeholder="Enter new player name"
            style={{ marginRight: 8, width: 200 }}
          />
          <Button onClick={handleAddPlayer} disabled={!newPlayerName.trim()}>
            Add Player
          </Button>
        </div>
        <div className="action-buttons">
          <Button onClick={handleReshuffle} type="primary" className="reshuffle-button">
            <ArrowClockwise size={20} weight="bold" />
            Reshuffle Teams
          </Button>
          <Button
            onClick={handleSelectRandomJack}
            type="default"
            className="select-jack-button"
            disabled={!isJackSelectionEnabled()}
          >
            <UserCheck size={20} />
            Select Random Jack
          </Button>
          <Button onClick={showModal} type="default" className="share-button">
            <Share size={20} />
            Share Teams
          </Button>
        </div>
        
        <Modal 
          title={
            <div className="modal-header">
              <Trophy size={28} weight="fill" style={{ marginRight: '10px', color: '#FFD700' }} />
              Share Teams
            </div>
          } 
          visible={isModalVisible} 
          onCancel={handleCancel} 
          footer={null}
          width={700}
          centered
        >
          <div className="shared-view">
            <h2><UsersThree size={24} weight="fill" /> Teams Lineup</h2>
            <div className="teams-container">
              {teams.map((team, index) => (
                <div key={team.id} className="team-column">
                  <h3>
                    <ShieldStar size={20} weight="fill" style={{ marginRight: '8px' }} />
                    {team.name}
                  </h3>
                  <ul className="player-list">
                    {team.players.map((player) => (
                      <li key={player.PlayerName} className="player-item">
                        <span className="player-name">
                          {player.displayName} 
                          {player.isCaptain && <Crown size={20} weight="fill" color="#ffcc00" />}
                          {jackPlayer === player.PlayerName && <UserCheck size={18} weight="fill" color="#00aaff" />}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <h2><Baseball size={24} weight="fill" /> Game Details</h2>
            <div className="game-settings-display">
              <p><span className="setting-label">Format:</span> {format}</p>
              <p><span className="setting-label">Overs:</span> {overs}</p>
              <p><span className="setting-label">Max Overs Per Bowler:</span> {maxOversPerBowler}</p>
              <p><span className="setting-label"><Clock size={18} weight="fill" /> Game Time:</span> {gameTime.format('HH:mm')}</p>
            </div>
          </div>
        </Modal>

        <div className="teams-container">
          {teams.map((team, teamIndex) => (
            <div key={team.id} className={`team-column team-${teamIndex + 1}`}>
              <div className="team-name-container">
                <Input
                  value={team.name}
                  onChange={(e) => handleTeamNameChange(team.id, e.target.value)}
                  className="team-name-input"
                />
                <SortAscending
                  size={20}
                  className="sort-icon"
                  onClick={() => sortTeamPlayers(team.id)}
                />
              </div>
              <Droppable droppableId={team.id}>
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef} className="player-list">
                    {team.players.map((player, index) => (
                      <Draggable key={player.PlayerName} draggableId={player.PlayerName} index={index}>
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="player-item"
                          >
                            <span className="player-name">
                              {player.displayName} 
                              {player.isCaptain && <Crown size={20} weight="fill" color="#ffcc00" />}
                              {jackPlayer === player.PlayerName && <UserCheck size={18} weight="fill" color="#00aaff" />}
                            </span>
                            <div className="player-actions">
                              <XCircle
                                size={20}
                                color="#af0808"
                                weight="fill"
                                onClick={() => removePlayer(player, teamIndex)}
                                className="action-icon"
                              />
                              {teams.map((_, idx) => idx !== teamIndex && (
                                <Swap
                                  key={idx}
                                  size={20}
                                  color="#08af67"
                                  weight="fill"
                                  onClick={() => movePlayer(player, teamIndex, idx)}
                                  className="action-icon"
                                />
                              ))}
                              <Dropdown overlay={playerMenu(team.id, player)} trigger={['click']}>
                                <DotsThreeVertical size={20} className="player-options-icon" />
                              </Dropdown>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </div>
          ))}
        </div>
        <div className="unassigned-players">
          <h2>Unassigned Players</h2>
          <Droppable droppableId="unassigned" direction="horizontal">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="unassigned-player-list">
                {unassignedPlayers.map((player, index) => (
                  <Draggable key={player.PlayerName} draggableId={player.PlayerName} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="unassigned-player-item"
                      >
                        <span className="player-name">{player.displayName}</span>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <div className="game-settings">
          <div className="setting-item">
            <label>Format:</label>
            <Input
              value={format}
              onChange={(e) => setFormat(e.target.value)}
              style={{ width: 120 }}
            />
          </div>
          <div className="setting-item">
            <label>Overs:</label>
            <Input
              type="number"
              value={overs}
              onChange={(e) => setOvers(parseInt(e.target.value))}
              style={{ width: 60 }}
            />
          </div>
          <div className="setting-item">
            <label>Max Overs Per Bowler:</label>
            <Input
              value={maxOversPerBowler}
              onChange={(e) => setMaxOversPerBowler(e.target.value)}
              style={{ width: 60 }}
            />
          </div>
          <div className="setting-item">
            <label>Game Time:</label>
            <TimePicker
              value={gameTime}
              onChange={(time) => setGameTime(time)}
              format="HH:mm"
              defaultValue={moment('20:45', 'HH:mm')}
              style={{ width: 100 }}
            />
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default TeamsPage;