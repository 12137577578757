import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Header from './components/common/Header';
import Home from './components/home/Home';
import LotteryPicker from './components/lottery/LotteryPicker';
import CoinFlipper from './components/coin/CoinFlipper';
import DiceRoller from './components/dice/DiceRoller';
import StockPicker from './components/stock/StockPicker';
import RandomNumberGenerator from './components/random/RandomNumberGenerator';
import ArclPage from './components/sports/cricket/ArclPage';
import SimulationPage from './components/sports/cricket/SimulationPage';
import BrclPage from './components/sports/brcl/BrclPage';
import TeamsPage from './components/sports/brcl/TeamsPage';
import theme from './theme';
import './theme/globalStyles.css';
import './App.css';

const App = () => {
  // Configure Ant Design theme to match our custom theme
  const antTheme = {
    token: {
      colorPrimary: theme.colors.primary,
      colorSuccess: theme.colors.success,
      colorWarning: theme.colors.warning,
      colorError: theme.colors.error,
      colorInfo: theme.colors.secondary,
      fontFamily: theme.typography.fontFamily,
      borderRadius: parseInt(theme.borderRadius.md.replace('rem', '')) * 16,
    },
  };

  return (
    <ConfigProvider theme={antTheme}>
      <Router>
        <div className="app-container">
          <Header />
          <div className="App">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/lottery-picker" element={<LotteryPicker />} />
              <Route path="/coin-flipper" element={<CoinFlipper />} />
              <Route path="/dice-roller" element={<DiceRoller />} />
              <Route path="/stock-picker" element={<StockPicker />} />
              <Route path="/random-number-generator" element={<RandomNumberGenerator />} />
              <Route path="/arcl-points-calculator" element={<ArclPage />} />
              <Route path="/arcl" element={<ArclPage />} />
              <Route path="/arcl-simulate-results" element={<SimulationPage />} />
              <Route path="/brcl2" element={<BrclPage />} />
              <Route path="/brclmaketeams" element={<TeamsPage />} />
            </Routes>
          </div>
        </div>
      </Router>
    </ConfigProvider>
  );
};

export default App;
